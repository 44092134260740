<template>
  <div class="container">
    <PublicHeader :totalName="$t('news').title" />
    <van-tabs v-model="active" line-width="28" line-height="3" background="#fff" color="#C92B2E"
      title-active-color="#C92B2E" title-inactive-color="#333333" sticky offset-top="40">
      <!-- 报刊文章 -->
      <van-tab :title="$t('news').Newspaper">
        <div class="new_list">
          <van-list v-model="news_loading" :finished="news_finished" :loading-text="$t('public').loadingText"
            @load="getNewsList" :immediate-check="false">
            <div class="item" @click="$router.push({ name: 'Detail', query: { id: item.Id } })"
              v-for="(item, index) in newsList" :key="`news_bk${index}`"
              :style="index == newsList.length - 1 ? 'border-bottom: none' : ''">
              <div class="txt">
                <div class="name">{{ item.Ttile }}</div>
                <div class="time">{{ item.ShowCreateTime | getLocalDate }}</div>
              </div>
              <img :src="item.Img" width="65" height="65" />
            </div>
          </van-list>
        </div>
      </van-tab>
      <!-- 投资研究 -->
      <van-tab :title="$t('news').Investment">
        <div class="new_list">
          <van-list v-model="news_loading" :finished="news_finished" :loading-text="$t('public').loadingText"
            @load="getNewsList" :immediate-check="false">
            <div class="item" @click="$router.push({ name: 'Detail', query: { id: item.Id } })"
              v-for="(item, index) in newsList" :key="`news_bk${index}`"
              :style="index == newsList.length - 1 ? 'border-bottom: none' : ''">
              <div class="txt">
                <div class="name">{{ item.Ttile }}</div>
                <div class="time">{{ item.ShowCreateTime | getLocalDate }}</div>
              </div>
              <img :src="item.Img" width="65" height="65" />
            </div>
          </van-list>
        </div>
      </van-tab>
      <!-- 操作指南 -->
      <van-tab :title="$t('news').MarketJournal">
        <div class="new_list">
          <van-list v-model="news_loading" :finished="news_finished" :loading-text="$t('public').loadingText"
            @load="getNewsList" :immediate-check="false">
            <div class="item" @click="$router.push({ name: 'Detail', query: { id: item.Id } })"
              v-for="(item, index) in newsList" :key="`news_sc${index}`"
              :style="index == newsList.length - 1 ? 'border-bottom: none' : ''">
              <div class="txt">
                <div class="name">{{ item.Ttile }}</div>
                <div class="time">{{ item.ShowCreateTime | getLocalDate }}</div>
              </div>
              <img :src="item.Img" width="65" height="65" />
            </div>
          </van-list>
        </div>
      </van-tab>
      <!-- 投资学堂 -->
      <van-tab :title="$t('news').InvestSchool">
        <div class="new_list">
          <van-list v-model="news_loading" :finished="news_finished" :loading-text="$t('public').loadingText"
            @load="getNewsList" :immediate-check="false">
            <div class="item" @click="$router.push({ name: 'Detail', query: { id: item.Id } })"
              v-for="(item, index) in newsList" :key="`news_tz${index}`"
              :style="index == newsList.length - 1 ? 'border-bottom: none' : ''">
              <div class="txt">
                <div class="name">{{ item.Ttile }}</div>
                <div class="time">{{ item.ShowCreateTime | getLocalDate }}</div>
              </div>
              <img :src="item.Img" width="65" height="65" />
            </div>
          </van-list>
        </div>
      </van-tab>
      <!-- 投资公告 -->
      <van-tab :title="$t('news').FundNotice">
        <div class="new_list">
          <van-list v-model="news_loading" :finished="news_finished" :loading-text="$t('public').loadingText"
            @load="getNewsList" :immediate-check="false">
            <div class="item" @click="$router.push({ name: 'Detail', query: { id: item.Id } })"
              v-for="(item, index) in newsList" :key="`news_jj${index}`"
              :style="index == newsList.length - 1 ? 'border-bottom: none' : ''">
              <div class="txt">
                <div class="name">{{ item.Ttile }}</div>
                <div class="time">{{ item.ShowCreateTime | getLocalDate }}</div>
              </div>
              <img :src="item.Img" width="65" height="65" />
            </div>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import systemApi from '@/api/system'
import { mapGetters } from 'vuex'
import PublicHeader from '@/components/publicHeader'

export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      isRun: false,
      active: 0,
      type: 'Newspaper',
      newsList: [],
      news_loading: false,
      news_finished: false,
      news_parms: {
        page: 1,
        size: 10,
      },
    }
  },
  watch: {
    active() {
      this.changeTab()
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    this.getNewsList()
  },
  methods: {
    // 切换
    changeTab() {
      if (this.active == 0)
        this.type = 'Newspaper'
      if (this.active == 1)
        this.type = 'Investment'
      if (this.active == 2)
        this.type = 'MarketJournal'
      if (this.active == 3)
        this.type = 'InvestSchool'
      if (this.active == 4)
        this.type = 'FundNotice'
      this.newsList = []
      this.news_parms.page = 1
      this.news_finished = false;
      this.getNewsList()
    },
    // 新闻列表
    async getNewsList() {
      if (this.isRun) return
      this.isRun = true
      const res = await systemApi.getContentTitles(this.type, this.news_parms)
      this.newsList.push(...res.Data);
      this.isRun = false
      this.news_parms.page++
      // 加载状态结束
      this.news_loading = false;
      // 数据全部加载完成
      if (this.newsList.length >= res.TotalCount) {
        this.news_finished = true;
      }
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  padding: 60px 5px 10px 5px;
  background: #fff;

  ::v-deep .van-tab {
    font-size: 15px;
  }

  ::v-deep .van-tab--active {
    font-weight: bold;
  }

  .new_list {
    padding: 10px 8px;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      border-bottom: 1px solid #E3E3E3;

      .txt {
        width: 250px;

        .name {
          font-weight: bold;
          font-size: 15px;
          color: #333333;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 21px;
        }

        .time {
          margin-top: 5px;
          font-size: 12px;
          color: #999999;
        }
      }
    }
  }
}
</style>
