import { fetch, post } from '@/utils/axios'

export default {
  // 上传文件
  UploadFile: (data) => {
    return post('/Captcha/file', data)
  },
  // 获取客户端配置
  getClientConfig: (params) => {
    return fetch('/Client/config', params)
  },
  // 获取内容列表
  getContentTitles: (type, params) => {
    return fetch(`/Content/${type}/titles`, params)
  },
  // 获取内容详情
  getContentDetail: (params) => {
    return fetch('/Content/detail', params)
  },
  // 获取轮播图列表
  getRotation: (params) => {
    return fetch('/Rotation', params)
  },
  // 获取系统钱包地址
  GetSystemWalletAddress: (params) => {
    return fetch('/Client/GetSystemWalletAddress', params)
  },

}
